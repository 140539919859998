<template>
  <div class="pl-4 pr-1" style="width: 100%">
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-stepper v-model="e1" class="elevation-0 background-transparent">
          <v-stepper-header class="no-box-shadow">
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
            >
              {{ $lang.header.resourcesProperties }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
            >
              {{ $lang.header.values }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              {{ $lang.header.result }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-6 pa-2 no-box-shadow"
                color="surface"
                min-height="366px"
              >
                <v-row no-gutters>
                  <v-col cols="6" class="col-class-left">
                    <p>{{ $lang.header.selectResourceTypesToBeEdited }}</p>
                    <template v-for="(type, index) in resourcesFormatted">
                      <v-checkbox
                        :key="index"
                        v-model="selectedResources"
                        class="pt-1"
                        hide-details
                        dense
                        multiple
                        :label="type.text"
                        :value="type.value"
                      />
                    </template>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <p>{{ $lang.header.selectPropertyYouWantToEdit }}</p>
                    <v-select
                      v-model="permissionType"
                      :items="typesFormatted"
                      :label="$lang.labels.propertyAction"
                      outlined
                      dense
                      clearable
                      item-text="text"
                      item-value="value"
                    />

                    <p class="pt-2">{{ $lang.header.typeOfChange }}</p>
                    <v-select
                      v-model="changeType"
                      :items="formattedChangeTypes"
                      :label="$lang.header.typeOfChange"
                      outlined
                      dense
                      item-text="text"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn
                  color="primary"
                  :disabled="!selectedResources.length || !permissionType"
                  @click="e1 = 2"
                >
                  {{ $lang.actions.next }}
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-6 pa-2 no-box-shadow"
                color="surface"
                min-height="366px"
              >
                <v-row no-gutters>
                  <v-col cols="6" class="col-class-left pr-2">
                    <h3>{{ $lang.header.resources }}</h3>
                    <template v-for="(item, i) in selectedResources">
                      <values-component
                        :key="i"
                        :type="item"
                        :all-resources="resourcesTypes"
                        @setValues="setResources(item, $event)"
                      ></values-component>
                    </template>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <h3>{{ $lang.header.newValues }}</h3>
                    <v-row v-if="permissionType === 'ROLE'" wrap no-gutters class="py-2">
                      <v-col cols="12" class="pb-2">
                        <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                          <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                          <user-roles-select
                            :role="editRolesIds"
                            :options="allowedRoles"
                            data-cy="roles-edit"
                            :required="false"
                            @changeRoles="editRolesIds = $event"
                          ></user-roles-select>
                          <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                          <user-roles-select
                            :role="useRolesIds"
                            :options="useRolePool"
                            data-cy="roles-use"
                            :required="false"
                            @changeRoles="useRolesIds = $event"
                          ></user-roles-select>
                          <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                          <user-roles-select
                            :role="viewRolesIds"
                            :options="viewRolePool"
                            data-cy="roles-view"
                            :required="false"
                            @changeRoles="viewRolesIds = $event"
                          ></user-roles-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn text class="mr-2" @click="e1 = 1">
                  {{ $lang.actions.back }}
                </v-btn>

                <v-btn
                  color="primary"
                  :disabled="!resourcesToBeChanged.length"
                  @click="yesNoShow = true"
                >
                  {{ $lang.actions.run }}
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-6 pa-2 no-box-shadow"
                color="surface"
                min-height="366px"
              >
                <v-row
                  v-if="loading"
                  wrap
                  no-gutters
                  class="py-2"
                  justify="center"
                >
                  <v-progress-circular
                    :rotate="-90"
                    :size="150"
                    :width="12"
                    :value="progressPosition"
                    color="primary"
                  >
                    <h4>{{ progressPosition }}%</h4>
                  </v-progress-circular>
                </v-row>
                <v-row v-else wrap no-gutters class="py-2">
                  <v-col v-if="errorsFromRun.length" cols="6" class="col-class-left pr-2">
                    <template v-for="(item, i) in errorsFromRun">
                      <p :key="i">{{ item }}</p>
                    </template>
                  </v-col>
                  <v-col v-if="successFromRun.length" cols="6" class="pl-2">
                    <template v-for="(item, n) in successFromRun">
                      <p :key="n">{{ item }}</p>
                    </template>
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn v-if="errorsFromRun.length" text class="mr-2" @click="e1 = 2">
                  {{ $lang.actions.back }}
                </v-btn>
                <v-btn text class="mr-2" @click="resetForm()">
                  {{ $lang.actions.reset }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
      <yes-no-modal :title="$lang.actions.start" :action-text="textForRunModal" @submit="startRunProcess" @closeDialog="yesNoShow = false"></yes-no-modal>
    </v-dialog>
  </div>
</template>

<script>
import {
  getRolesUsingGET as getRoles,
  updateTranslationRolesUsingPUT as updateTranslation,
  updateModuleRolesUsingPUT as updateModule,
  updateProcessRolesUsingPUT as updateProcess,
  updateTriggerRestRolesUsingPUT as updateRest,
  updatePluginRolesUsingPUT as updatePlugin,
  updateTextTemplateRolesUsingPUT as updateTemplate,
  updateTriggerCronRolesUsingPUT as updateCron,
  updateValidationRuleRolesUsingPUT as updateValidation,
  updateTriggerMessagingRolesUsingPUT as updateMessaging,
  updateProcessCredentialRolesUsingPUT as updateProcessCredentialRoles,
  updateLibraryRolesUsingPUT as updateLibrary,
  updateTriggerEventHandlerRolesUsingPUT as updateEventHandler,
  updateEntityRolesUsingPUT as updateEntity
} from '@/utils/api'
import ValuesComponent from '@/pages/mass-change/Values.vue'
import UserRolesSelect from '@/components/ui/UserRolesSelect.vue'
import YesNoModal from '@/components/ui/modals/YesNoModal.vue'
import axios from 'axios'
import { mapActions } from 'vuex'
import { getRolesWithoutAuth } from '@/utils/helpers'

export default {
  components: { YesNoModal, UserRolesSelect, ValuesComponent },
  data() {
    return {
      yesNoShow: false,
      e1: 1,
      err: '',
      success: '',
      loading: false,
      resourcesTypes: ['GLOBAL_TRANSLATION', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'VALIDATION_RULE', 'LIBRARY', 'ENTITY'],
      selectedResources: [],
      resourcesTypesLinks: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        LIBRARY: 'libraries/edit',
        STORAGE: 'storages/edit',
        TRIGGER_EVENT_HANDLER: 'triggers/event-handler/edit',
        ENTITY: 'entities/edit'
      },
      propertyTypes: ['ROLE'],
      permissionType: 'ROLE',
      allRoles: [],
      rolesWithoutAuth: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      resourcesToBeChanged: [],
      errorsFromRun: [],
      successFromRun: [],
      finishedResourcesRun: 0,
      changeType: 'ADD',
      changeTypes: ['ADD', 'REMOVE', 'REPLACE']
    }
  },
  computed: {
    textForRunModal() {
      if (this.changeType === 'ADD') {
        return this.$lang.labels.thisWillAdd
      } else if (this.changeType === 'REMOVE') {
        return this.$lang.labels.thisWillRemove
      } else if (this.changeType === 'REPLACE') {
        return this.$lang.labels.thisWillOverwrite
      }

      return ''
    },
    formattedChangeTypes() {
      return this.changeTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    },
    progressPosition() {
      return (this.finishedResourcesRun / this.resourcesToBeChanged.length * 100).toFixed(0)
    },
    allowedRoles() {
      // Roles with auth and unauth are allowed only for rest triggers
      return this.selectedResources.length === 1 && this.selectedResources.includes('TRIGGER_REST') 
        ? this.allRoles
        : this.rolesWithoutAuth
    },
    useRolePool() {
      return this.allowedRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allowedRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    resourcesFormatted() {
      return this.resourcesTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      }).sort((a, b) => a.text.localeCompare(b.text))
    },
    typesFormatted() {
      return this.propertyTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  created() {
    this.init()
    if (this.$route.params && this.$route.params.message) {
      
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    resetForm() {
      this.e1 = 1
      this.err = ''
      this.success = ''
      this.loading = false
      this.selectedResources = []
      this.permissionType = 'ROLE'
      this.editRolesIds = []
      this.useRolesIds = []
      this.viewRolesIds = []
      this.resourcesToBeChanged = []
      this.errorsFromRun = []
      this.successFromRun = []
      this.finishedResourcesRun = 0
    },
    async fetchExistingResource (type, id) {

      const resourcesTypes = {
        GLOBAL_TRANSLATION: 'translation',
        MODULE: 'module',
        PROCESS: 'process',
        PROCESS_CREDENTIAL: 'process-credential',
        TEXT_TEMPLATE: 'text-template',
        TRIGGER_CRON: 'trigger-cron',
        TRIGGER_REST: 'trigger-rest',
        TRIGGER_MESSAGING: 'trigger-messaging',
        VALIDATION_RULE: 'validation-rule',
        PLUGIN: 'plugin',
        LIBRARY: 'library',
        STORAGE: 'storage',
        TRIGGER_EVENT_HANDLER: 'trigger-event-handler',
        ENTITY: 'entity'
      }

      const axiosUrl = `/api/${resourcesTypes[type]}/${id}`

      if (axiosUrl) {
        try {
          const res = await axios.get(axiosUrl)

          if (res.status !== 200 || !res.data?.data) {
            return false
          }

          return res.data?.data
        } catch (e) {
          console.log(e)

          return false
        }
      } else {
        return false
      }
    },
    async updateRolesForResource(resource) {

      const data = {}

      const tempRoles = []

      const editRolesIdsLocal = JSON.parse(JSON.stringify(this.editRolesIds))
      const useRolesIdsLocal = JSON.parse(JSON.stringify(this.useRolesIds))
      const viewRolesIdsLocal = JSON.parse(JSON.stringify(this.viewRolesIds))

      if (this.changeType === 'ADD') {
        const existingResource = await this.fetchExistingResource(resource.type, resource.id)

        if (existingResource) {
          const  existingEditRoles = existingResource.roles.filter((x) => x.permissionType === 'EDIT')
          const  existingUseRoles = existingResource.roles.filter((x) => x.permissionType === 'USE')
          const  existingViewRoles = existingResource.roles.filter((x) => x.permissionType === 'VIEW')

          existingEditRoles.forEach((x) => {
            if (!editRolesIdsLocal.includes(x.role.id)) {
              editRolesIdsLocal.push(x.role.id)
            }
          })

          existingUseRoles.forEach((x) => {
            if (!useRolesIdsLocal.includes(x.role.id)) {
              useRolesIdsLocal.push(x.role.id)
            }
          })

          existingViewRoles.forEach((x) => {
            if (!viewRolesIdsLocal.includes(x.role.id)) {
              viewRolesIdsLocal.push(x.role.id)
            }
          })
        }
      } else if (this.changeType === 'REMOVE') {
        const existingResource = await this.fetchExistingResource(resource.type, resource.id)

        if (existingResource) {
          const  existingEditRoles = existingResource.roles.filter((x) => x.permissionType === 'EDIT' && !editRolesIdsLocal.includes(x.role.id))
          const  existingUseRoles = existingResource.roles.filter((x) => x.permissionType === 'USE' && !useRolesIdsLocal.includes(x.role.id))
          const  existingViewRoles = existingResource.roles.filter((x) => x.permissionType === 'VIEW' && !viewRolesIdsLocal.includes(x.role.id))

          existingEditRoles.forEach((x) => {
            tempRoles.push({ permissionType: 'EDIT', roleId: x.role.id })
          })

          existingUseRoles.forEach((x) => {
            tempRoles.push({ permissionType: 'USE', roleId: x.role.id })
          })

          existingViewRoles.forEach((x) => {
            tempRoles.push({ permissionType: 'VIEW', roleId: x.role.id })
          })
        }
      }

      if (this.changeType !== 'REMOVE') {
        editRolesIdsLocal.forEach((x) => {
          tempRoles.push({ permissionType: 'EDIT', roleId: x })
        })

        useRolesIdsLocal.forEach((x) => {
          tempRoles.push({ permissionType: 'USE', roleId: x })
        })

        viewRolesIdsLocal.forEach((x) => {
          tempRoles.push({ permissionType: 'VIEW', roleId: x })
        })
      }

      data.roles = tempRoles

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        switch (resource.type) {
        case 'GLOBAL_TRANSLATION':
          updateTranslation({ id: resource.id, body: data })
            .then(() => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'MODULE':
          updateModule({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS':
          updateProcess({
            id: resource.id,
            body: data
          })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS_CREDENTIAL':
          updateProcessCredentialRoles({
            id: resource.id,
            body: data
          })
            .then((res) => {
              resolve(true)
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TEXT_TEMPLATE':
          updateTemplate({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_CRON':
          updateCron({
            id: resource.id,
            body: data
          })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_REST':
          updateRest({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'VALIDATION_RULE':
          updateValidation({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PLUGIN':
          updatePlugin({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_MESSAGING':
          updateMessaging({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'LIBRARY':
          updateLibrary({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_EVENT_HANDLER':
          updateEventHandler({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'ENTITY':
          updateEntity({ id: resource.id, body: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        default:
        }
      })
    },
    startRunProcess() {
      this.yesNoShow = false
      this.e1 = 3
      this.loading = true
      this.err = ''
      this.success = ''
      this.finishedResourcesRun = 0

      const result = this.resourcesToBeChanged.map(async (x) => {

        let resourceUpdate = false

        switch (this.permissionType) {
        case 'ROLE':
          resourceUpdate = await this.updateRolesForResource(x)

          break
        default:
        }

        if (resourceUpdate) {
          this.successFromRun.push(`Successfully updated ${this.$lang.status[x.type]}: ${x.id}!`)
          this.finishedResourcesRun++

          return true
        } else {
          this.errorsFromRun.push(`Error while updating ${this.$lang.status[x.type]}: ${x.id}!`)
          this.finishedResourcesRun++

          return false
        }
      })

      Promise.all(result)
        .then((res) => {
          this.loading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.loading = false
        })
    },
    async init() {
      const rolesWithoutAuth = await getRolesWithoutAuth()

      this.rolesWithoutAuth = rolesWithoutAuth

      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    setResources(type, data) {
      const tempByType = this.resourcesToBeChanged.filter((x) => x.type !== type)

      const formattedData = data.map((x) => {
        return { type, id: x.id ? x.id : x }
      })

      this.resourcesToBeChanged = [...tempByType, ...formattedData]
    },
    iconHandler(type) {
      switch (type) {
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'MODULE':
        return 'mdi-view-module-outline'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'PROCESS':
        return 'mdi-console-network'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'SETTING':
        return 'mdi-cog'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_MESSAGING':
        return 'mdi-message-text-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'USER':
        return 'mdi-account'
      case 'LIBRARY':
        return 'mdi-library-outline'
      case 'STORAGE':
        return 'mdi-cube'
      case 'TRIGGER_EVENT_HANDLER':
        return 'mdi-briefcase-outline'
      case 'ENTITY':
        return 'mdi-database-settings-outline'
      default:
        return 'mdi-home'
      }
    }
  }
}
</script>
<style lang="scss">
.col-class-left {
  border-right: 1px solid var(--v-primary-base);
}

.no-box-shadow {
  box-shadow: none !important;
}

.v-application .surface {
  background-color: transparent !important;
}
</style>
